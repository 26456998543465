import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import Home from './Pages/Home';
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Navbar from './Components/Navigation/Navbar/Navbar';
import Art from './Pages/Art';
import TTRPG from './Pages/TTRPG';
import TokenGallery from './Pages/TokenGallery';
import CounterBury from './Pages/CounterBury';
import Footer from './Components/Navigation/Footer/Footer';
import About from './Pages/About';
import ContentContainer from './Components/Shared/ContentContainer';
import ScrollToTop from './Utils/ScrollToTop';
import CounterburyArchive from './Pages/CounterburyArchive';

const links = [
  {
    text: "Art",
    path: "art",
    component: <Art />
  },
  {
    text: "TTRPG",
    path: "ttrpg",
    component: <TTRPG />
  }
]

const excludedPaths = [
  'counterbury-tales'
]

function App() {

  const routes = links.map(link => (
    <Route path={`/${link.path}`} element={link.component} key={link.path} />
  ))

  return (
    <HelmetProvider>
    <div className="App">
      <Helmet>
        <title>Tale-Top: Art and Adventure</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#9acdff" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Helmet>
      <BrowserRouter>
        <ScrollToTop>
        <Navbar links={links} excludedPaths={excludedPaths}/>
        <ContentContainer>
          <Routes>
            <Route path="/" element={<Home />} />
            {routes}
            {/* <Route path="/art/tokens" element={<TokenGallery />} /> */}
            <Route path="/counterbury-tales" element={<CounterBury />} />
            <Route path="/counterbury-tales/archive" element={<CounterburyArchive />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </ContentContainer>
        <Footer  excludedPaths={excludedPaths}/>
        </ScrollToTop>
      </BrowserRouter>
    </div>
    </HelmetProvider>
  );
}

export default App;
